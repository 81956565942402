<template>
  <div>
    <GmapMap :zoom="zoom" :center="center" style="width: 100%; height: 500px" :options="options">
      <DirectionsRenderer 
        travelMode="DRIVING"
        :origin="startLocation"
        :destination="endLocation"
        :waypoints="waypoints"
        :optimizeWaypoints="true"
      />
      <GmapMarker v-for="(m, index) in markers" :position="m.position" :title="m.title" :infoText="m.infoText" :label="m.label" :key="index" :clickable="true"
      :draggable="true" :shape="shape" :icon="m.icon"></GmapMarker>
    </GmapMap>
    <br>
          <!-- Filters -->
    <div id="table"></div>
    <TripFilter
      :ride-filter.sync="rideFilter"
      :payment-filter.sync="paymentFilter"
      :status-filter.sync="statusFilter"
      :payment-options="paymentOptions"
      :ride-options="rideOptions"
      :status-options="statusOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0">

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

           <!-- Referesh -->
          <b-col cols="12"
          md="3">
             <b-button
                variant="primary"
                @click="refreshData()"
              >
               <feather-icon icon="RefreshCcwIcon" />
              </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refTripTable"
        class="position-relative"
        :items="trips"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

      <template #cell(show_details)="row">

          <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
          <b-form-checkbox
            v-model="row.detailsShowing"
            @change="row.toggleDetails"
          >
            {{ row.detailsShowing ? 'Hide' : 'Show' }}
          </b-form-checkbox>
        </template>

           <!-- full detail on click -->
        <template #row-details="row">
          <b-card>
           <b-tabs content-class="mt-3">
                <b-tab title="Delivery Details" active>
                     <div class="text-nowrap">
            <p>Category: <strong>
              {{row.item.packageCategory}}
              </strong>
            </p>
            <p>Size: 
              <strong>
              {{row.item.packageSize}}
              </strong>
            </p>
            <p>Extra Description: 
              <strong>
              {{row.item.extraDescription}}
              </strong>
            </p>
            <p>Extra Instruction: 
              <strong>
              {{row.item.extraInstructions}}
              </strong>
            </p>
          </div>
                </b-tab>
                <b-tab title="Dropoffs">
                     <div v-for="delivery in row.item.deliveries" :key="delivery.id" class="text-nowrap">
            <p v-if="delivery.endLocationAddress">Location: <strong>
              {{delivery.endLocationAddress}}
              </strong>
            </p>
            <p v-if="delivery.recipientFullName">Recipient: 
              <strong>
              {{delivery.recipientFullName}} | {{delivery.recipientPhoneNumber}} | {{delivery.recipientEmailAddress}}
              </strong>
            </p>
            <p v-if="delivery.status">Status: 
              <strong>
              {{delivery.status}}
              </strong>
            </p>
            <hr>
          </div>
                </b-tab>
                <b-tab title="Time">
                              <div class="text-nowrap">
            <p>Estimated Time: <strong>
              {{Math.round(row.item.estimatedTime/60)}} min
              </strong>
            </p>
            <p>Actual Time: <strong>
              {{Math.round(dateDiff(new Date(row.item.startTime), new Date(row.item.endTime)))}} min
              </strong>
            </p>
            <p>Request Date: <strong>
              {{formatDate(row.item.requestTime, {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'})}}
              </strong>
            </p>
            <p>Request Time: <strong>
              {{formatDate(row.item.requestTime, {hour: 'numeric', minute: 'numeric', second: 'numeric'})}}
              </strong>
            </p>
            <p>Arrival Time: 
              <strong>
              {{formatDate(row.item.arrivalTime, {hour: 'numeric', minute: 'numeric', second: 'numeric'})}}
              </strong>
            </p>
            <p>Start Time: 
              <strong>
              {{formatDate(row.item.startTime, {hour: 'numeric', minute: 'numeric', second: 'numeric'})}}
              </strong>
            </p>
            <p>End Time: 
              <strong>
              {{formatDate(row.item.endTime, {hour: 'numeric', minute: 'numeric', second: 'numeric'})}}
              </strong>
            </p>
          </div>
                </b-tab>
            </b-tabs>
            <b-button
              size="sm"
              variant="outline-secondary"
              @click="row.toggleDetails"
            >
              Hide Details
            </b-button>
          </b-card>
        </template>


        <!-- Column: Driver -->
       <template #cell(rider)="data">
          <b-media v-if="data.item.rider" vertical-align="center">
            <template #aside>
              <b-avatar 
                size="32"
                :src="resolvePhoto(data.item.rider.profile.photo)"
                :text="avatarText(`${data.item.rider.profile.firstName} ${data.item.rider.profile.lastName}`)"
                
                
              />
            </template>
            <b-link v-if="data.item.rider"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.rider.profile.firstName }} {{data.item.rider.profile.lastName}}
            </b-link>
            <small class="text-mute">{{ data.item.rider.phone }}</small> <br>
          </b-media>
        </template>

         <!-- Column: User -->
       <template #cell(user)="data">
          <b-media v-if="data.item.user" vertical-align="center">
            <template #aside>
              <b-avatar 
                size="32"
                :src="resolvePhoto(data.item.user.profile.photo)"
                :text="avatarText(`${data.item.user.profile.fullName}`)"
                
                
              />
            </template>
            <b-link v-if="data.item.user"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.user.profile.fullName }}
            </b-link>
            <small class="text-mute">{{ data.item.user.phone }}</small> <br>
          </b-media>
        </template>

        <template #cell(payment)="data">
          <div v-if="data.item.payment" class="text-nowrap">
            <p>Type: <strong>
              {{data.item.payment.type}}
              </strong>
            </p>
            <p>Price: 
              <strong>
              {{data.item.payment.totalPrice}}
              </strong>
            </p>
          </div>
        </template>

          <!-- Column: Status -->
        <template #cell(createdAt)="data">
           <div v-if="data.item.createdAt" class="text-nowrap">
            <strong>
              {{formatDate(Date.parse(data.item.createdAt), {month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'})}}
              </strong>
           </div>
        </template>    
        

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="button"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item-button @click="showOnMap(data.item)">
              <feather-icon icon="MapIcon" />
              <span>View on map</span>
            </b-dropdown-item-button>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalTrips"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BCardText,
  BBadge, BDropdown, BDropdownItem, BDropdownItemButton, BPagination, BTabs, BTab,BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import { avatarText, formatDate, dateDiff } from '@core/utils/filter'
import TripFilter from './components/TripFilter.vue'
import useOngoing from './useOngoing'
import Ripple from 'vue-ripple-directive'
import DirectionsRenderer from '@/@core/components/directions-renderer'
import {   LMap,
  LTileLayer,
  LMarker,
  LPolyline,
  LLayerGroup,
  LTooltip,
  LPopup,
  LControlZoom,
  LControlAttribution,
  LControlScale,
  LControlLayers, } from 'vue2-leaflet'
import { latLngBounds, Icon } from 'leaflet'
import Pusher from 'pusher-js'
import 'leaflet/dist/leaflet.css'
import useJwt from '@/auth/jwt/useJwt'

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BPagination,
    BCard,
    BTabs,
    BTab,
    BFormCheckbox,
    BCardText,
    LMap,
    LTileLayer,
    LMarker,
    LPolyline,
    LLayerGroup,
    LTooltip,
    LPopup,
    LControlZoom,
    LControlAttribution,
    LControlScale,
    LControlLayers,
    DirectionsRenderer,

    vSelect,
    TripFilter
  },
  directives: {
    Ripple,
  },
   data() {
    return {
      zoom: 18,
      center: {lat: 9.072264, lng: 7.491302},
      options: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: true,
        fullscreenControl: true,
        disableDefaultUi: false,
        gestureHandling: 'cooperative'
      },
      startLocation:  null,
      endLocation:  null,
      waypoints: [],
       markers: [],
          shape: {
            coords: [10, 10, 10, 15, 15, 15, 15, 100],
            type: 'poly'
          },
    }
  },
  methods: {
    showOnMap(trip) {
    this.waypoints = []
    let sLatLng = trip.startLocation.split(',');
    let eLatLng = trip.deliveries[trip.deliveries.length - 1].endLocation.split(',');
 
    this.startLocation = { lat: parseFloat(sLatLng[0]), lng: parseFloat(sLatLng[1]) },
    this.endLocation = { lat: parseFloat(eLatLng[0]), lng: parseFloat(eLatLng[1]) }

    for(let i = 0; i < trip.deliveries.length - 2; i++) {
      let wpnt = trip.deliveries[i].endLocation.split(',')
      this.waypoints.push({
           location: { lat: parseFloat(wpnt[0]), lng: parseFloat(wpnt[1]) },
           stopover: true,
         });

    }

    if(trip.status == 'Trip Started') {
      console.log("tracking rider")
      // this.subscribe(trip.token);
    }
    // this.markers = []
    // this.markers.push({
    //   title: "",
    //   position: this.startLocation,
    //   label: "",
    //   clickable: true,
    //   infoText: '<strong>Marker 2</strong>',
    //   icon: require('@/assets/images/blink2.gif')
    // })
    
    // smooth scroll to map
    const rootEle = document.documentElement
      rootEle.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
     alert(item) {
      // eslint-disable-next-line no-alert
      alert(`this is ${JSON.stringify(item)}`)
    },
    addMarker() {
      const newMarker = {
        position: { lat: 50.5505, lng: -0.09 },
        draggable: true,
        visible: true,
      }
      this.markers.push(newMarker)
    },
    removeMarker(index) {
      this.markers.splice(index, 1)
    },
    fitPolyline() {
      const bounds = latLngBounds(markers1.map(o => o.position))
      this.bounds = bounds
    },
  },
  mounted() {
     console.log("mounted")
       const el = this.$el.querySelector("#table");

        if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({behavior: 'smooth'});
        }
  },
  setup() {

    onMounted(() => {
      fetchTrips()
    })

    // let pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, 
    //     { 
    //       cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    //       authEndpoint: `${process.env.VUE_APP_API_BASE_URL}/notifications/auth`,
    //       auth: {
    //       headers: { 'Authorization': `Bearer ${useJwt.getToken()}`,
    //     }}
    //     })

    const subscribe = (token) => {
      // pusher.subscribe('private-order-'+token)
      // pusher.bind('client-rider-location', data => {
      //   console.log(JSON.parse(data))
      //   let loc = JSON.parse(data)
      //       this.markers = []
      //     this.markers.push({
      //       title: "",
      //       position: {lat: loc['latitude'], lng: loc['longitude']},
      //       label: "",
      //       clickable: true,
      //       infoText: '',
      //       icon: require('@/assets/images/blink2.gif')
      //     })
      // })
    }

    const isAddNewDriverSidebarActive = ref(false)

    const paymentOptions = [
      { label: 'Cash', value: 'cash' },
      { label: 'Card', value: 'card' }
    ]

    const rideOptions = [
      { label: 'Bike', value: 'bike' },
      { label: 'Car', value: 'car' },
      { label: 'Truck', value: 'truck' },
    ]

    const statusOptions = [
      { label: 'Created', value: 'Created' },
      { label: 'Rider Found', value: 'Rider Found' },
      { label: 'Rider Arrived', value: 'Rider Arrived' },
      { label: 'Trip Started', value: 'Trip Started' },
      { label: 'Trip Ended', value: 'Trip Ended' },
      { label: 'User Cancelled', value: 'User Cancelled' },
    ]

    const {
      fetchTrips,
      trips,
      tableColumns,
      perPage,
      currentPage,
      totalTrips,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTripTable,
      refetchData,
      refreshData,

      // UI
      resolvePhoto,

      // Extra Filters
      rideFilter,
      paymentFilter,
      statusFilter,
    } = useOngoing()

    return {
      subscribe,
      // Sidebar
      isAddNewDriverSidebarActive,

      fetchTrips,
      trips,
    //   updateVerificationStatus,
      tableColumns,
      perPage,
      currentPage,
      totalTrips,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTripTable,
      refetchData,
      refreshData,

      // Filter
      avatarText,
      formatDate,
      dateDiff,

      // UI
      resolvePhoto,

      paymentOptions,
      rideOptions,
      statusOptions,

      // Extra Filters
      rideFilter,
      paymentFilter,
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.vue2leaflet-map{
  &.leaflet-container{
    height: 350px;
  }
}
</style>
